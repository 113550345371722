import * as React from 'react';
import { Paper, Stack, Link, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import './App.css';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  paddingLeft: '40px',
  paddingRight: '40px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const MyLink = styled(Link)(({ theme }) => ({
  color: '#000000',
}));

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Stack spacing={2}>
          <div align="center"><Avatar alt="Jens Dibbern" src="avatar.jpg" sx={{ width: 100, height: 100 }} /></div>
          <p align="center">@datengaertner</p>
          <Item><MyLink href="https://www.datengaertnerei.com/" underline='hover'>Datengärtnerei - mein persönliches Portfolio</MyLink></Item>
          <Item><MyLink href="https://datengaertnerei.github.io/" underline='hover'>Meine Seiten zu Software QA und Testing</MyLink></Item>
          <Item><MyLink href="https://www.jungheinrich.de/" underline='hover'>Jungheinrich AG - mein Arbeitgeber</MyLink></Item>
          <Item><MyLink href="https://www.linkedin.com/in/jensdibbern/" underline='hover'>LinkedIn Profil</MyLink></Item>
          <Item><MyLink href="https://mastodon.social/@datengaertner" underline='hover'>Mastodon Profil</MyLink></Item>
          <Item><MyLink href="https://github.com/datengaertnerei" underline='hover'>GitHub Entwicklerseite</MyLink></Item>
          <Item><MyLink href="https://stackoverflow.com/users/10937788" underline='hover'>StackOverflow Profil</MyLink></Item>
          <Item><MyLink href="https://raindrop.io/jens-dibbern/software-qa-tools-17390996" underline='hover'>Sammlung von Software QA Tools</MyLink></Item>
          <Item><MyLink href="https://keybase.io/datengaertner" underline='hover'>Keybase Profil</MyLink></Item>
        </Stack>
      </header>
    </div>
  );
}

export default App;
